import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Modal } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'

import { StudentsTableFreeCourse } from '../../components/free-course-table'
import { callApi } from '../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const studentListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/free-course-user/list-assigned'
}

const unassignFreeUserCourse = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/unassign-course'
}

const academicYearData = [
  { u_id: 1, value: '1', label: '1st year' },
  { u_id: 2, value: '2', label: '2nd year' },
  { u_id: 3, value: '3', label: '3rd year' },
  { u_id: 4, value: '4', label: '4th year' },
  { u_id: 5, value: '5', label: 'Internship' },
  { u_id: 6, value: '6', label: 'MD Part 1' },
  { u_id: 7, value: '7', label: 'MD Part 2' }
]

const ViewFreeCourse = props => {
  // ** id
  const id = props.match.params.id

  // ** states
  const [universityList, setUniversityList] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [academicYear, setAcademicYear] = useState('')
  const [gender, setGender] = useState('')
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [selectedStudentIds, setSelectedStudentIds] = useState([])

  const [studentList, setStudentList] = useState([])
  console.log('studentList :', studentList)
  const [recallApi, setRecallApi] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  // ** common student list

  async function StudentListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || '',
        pursuing_year: academicYear,
        gender,
        enrollment_course_id: id,
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    StudentListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage])

  // ** common pursuing year
  // Add event handlers for new filters
  const handleAcademicYearChange = e => {
    setAcademicYear(e.target.value)
  }

  const handleGenderChange = e => {
    setGender(e.target.value)
  }

  const handleAssignShow = () => setShowAssignModal(true)
  const handleAssignClose = () => {
    setShowAssignModal(false)
  }

  const handleAssign = async () => {
    const studentsToUpdate = studentList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status === 'approve'
    )
    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate?.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      // alert('No students with approval status "approve".')
      toast.error('No students with approval status "approve".')
      return
    }

    const body = {
      course_id: id,
      students: idsToUpdate
    }

    if (id || idsToUpdate) {
      try {
        const res = await callApi({
          uriEndPoint: unassignFreeUserCourse,
          body: body
        })

        if (res) {
          // Reset form fields after successful assignment
          setSelectedStudentIds([])
          setShowAssignModal(false)
          handleSearch()
          toast.success(res?.message ?? 'Courses successfully unassigned!!')
        }
      } catch (error) {
        toast.error(error?.message ?? 'Error occurred while assigning courses.')
      }
    }
  }

  // Search button handler common
  const handleSearch = async () => {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || '',
        pursuing_year: academicYear,
        gender,
        enrollment_course_id: id,
        page: currentPage, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint, query: { status: true } }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    setSelectedUniversity(e.target.value)
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Students assigned this course</h4>
        </div>
      </div>
      <div className=''>
        <div className='row gy-3 gx-3 mb-3'>
          {/* Uni */}
          <div className='form-group col-12 col-sm-6 col-lg-4'>
            <label htmlFor='inputPassword4'>Select University</label>
            <select
              id='inputState'
              value={selectedUniversity || ''} // value is controlled by state
              onChange={handelChange}
              name='university_id'
              className='form-control'
            >
              {/* Add a default placeholder option */}
              <option value='' disabled>
                Select University
              </option>
              {/* Populate options dynamically */}
              {universityList &&
                universityList?.allUniversity?.map(university => (
                  <option key={university?._id} value={university?._id}>
                    {university?.name}
                  </option>
                ))}
            </select>
          </div>
          {/* Year */}
          <div className='form-group col-12 col-sm-6 col-lg-3'>
            <label htmlFor='academicYear'>Pursuing Year</label>
            <select id='academicYear' value={academicYear} onChange={handleAcademicYearChange} className='form-control'>
              <option value=''>All</option>
              {/* Populate pursuing year options */}
              {academicYearData?.map(item => (
                <option key={item?.u_id} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          {/* Gender */}
          <div className='form-group col-12 col-sm-6 col-lg-3'>
            <label htmlFor='gender'>Gender</label>
            <select id='gender' value={gender} onChange={handleGenderChange} className='form-control'>
              <option value=''>All</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
          {/* Search */}
          <div className='col-sm-6 col-12 col-lg-2 d-flex align-items-end justify-content-center'>
            <button className='btn btn-primary w-100' onClick={() => handleSearch()}>
              Search
            </button>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-start gap-3 mb-3'>
          {/* Assign */}
          <div>
            <button className='btn btn-secondary' onClick={handleAssignShow} disabled={!selectedStudentIds?.length}>
              Unassign Course
            </button>
          </div>
        </div>
        <StudentsTableFreeCourse
          id={id}
          selectedUniversity={selectedUniversity}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          studentList={studentList}
          setRecallApi={setRecallApi}
          StudentListData={() => StudentListData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      <Modal show={showAssignModal} onHide={handleAssignClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Unassign Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>
              This will <b>unassign</b> this course for selected student(s)!
            </p>
            <p>
              No. of selected student(s) : <b>{selectedStudentIds?.length}</b>
            </p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleAssign}>
            Confirm Unassign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ViewFreeCourse
