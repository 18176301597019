import {
  faAd,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faEye,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Form, Modal, ProgressBar, Table } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useUserContext } from '../context/MyContext'

// import { Link } from 'react-router-dom'

import axios from 'axios'
import moment from 'moment'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { callApi } from '../utils/api-utils'
// import { app } from '../config/firebase'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

// const studentDelete = {
//   ...defaults.methods.GET,
//   uri: '/api/user/delete/:id'
// }

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const universityStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/university/update-status/:id'
}

const collegeStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/college/update-status/:id'
}

const collegeUpdateEndPoint = {
  ...defaults.methods.PUT,
  uri: '/api/college/update/:id'
}

const deleteUniversityEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/university/delete/:id'
}

const deleteCollegeEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/college/delete/:id'
}

const coordinatorListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/coordinator/list'
}
const coordinatorStatusUpdate = {
  ...defaults.methods.PATCH,
  uri: '/api/coordinator/update-status/:id'
}

const deleteCoordinatorEndPoint = {
  ...defaults.methods.DELETE,
  uri: '/api/coordinator/delete/:id'
}

const quizPoint = {
  ...defaults.methods.GET,
  uri: '/api/free-lesson-quiz/free-student-quiz-score'
}

// const studentReportListEndPoint = {
//   ...defaults.methods.GET,
//   uri: '/api/free-course-user/list'
// }

const studentApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/update-approval-status'
}

const facultyApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/faculty/update-approval-status'
}

const coachPasswordUpdateEndPoint = {
  ...defaults.methods.PATCH,
  uri: '/api/coordinator/update-password/:id'
}

export const FreeCourseTable = () => {
  const [courses, setCourses] = useState([])
  const { user } = useUserContext()

  const [idf, setIDF] = useState('')

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await axios
      .get('/api/free-course/list?type=front-end-api')
      .then(res => {
        const data = res?.data?.allFreeCourse || []
        data.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })

        setCourses(data)
      })
      .catch(e => alert(e))
  }

  function Delete(id) {
    axios
      .get(`/api/free-course/delete/${id}?type=front-end-api`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          toast.success('Course deleted Successfully!')
          Refresh()

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Course not deleted')
      })
  }

  const TableRow = props => {
    const { _id, name_of_course, index } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{name_of_course}</span>
        </td>

        <td>
          <button onClick={() => (window.location.href = `#/all-free-chapters/` + _id)} className='btn '>
            <FontAwesomeIcon icon={faPlus} className='me-2' /> Add Content
          </button>
        </td>

        <td>
          <div className='d-flex align-items-center gap-2'>
            <button onClick={() => (window.location.href = `#/view-free/` + _id)} className='btn border'>
              <FontAwesomeIcon icon={faEye} />
            </button>
            {user.role == 'super-admin' && (
              <button onClick={() => (window.location.href = `#/edit-free/` + _id)} className='btn border'>
                <FontAwesomeIcon icon={faEdit} />
              </button>
            )}
            <button
              onClick={() => {
                setIDF(_id)
              }}
              className='btn btn-danger'
              data-toggle='modal'
              data-target='#free-course-delete'
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <>
      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Sr No.</th>
                <th className='border-bottom'>Course Name</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Add Content</th>}
                {/* <th className='border-bottom'>Add Content</th> */}
                <th className='border-bottom'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((t, index) => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const FreeLessonTable = props => {
  const [courses, setCourses] = useState([])

  const [idf, setIDF] = useState('')
  const { user } = useUserContext()

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await axios
      .get(`/api/free-lesson/list/${props.course_id}?front-end-api`)
      .then(res => {
        setCourses(res?.data?.allFreeLesson || [])

        // console.log(res)
      })
      .catch(e => console.log(e))
  }

  function Delete(id) {
    axios
      .get(`/api/free-lesson/delete/${id}?type=front-end-api`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          setIDF('')
          Refresh()
          toast.success('Lesson deleted Successfully!')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Lesson not deleted')
      })
  }

  const TableRow = props => {
    const { _id, lesson_name } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{lesson_name}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button onClick={() => (window.location.href = `#/edit-free-lesson/` + _id)} className='btn '>
              <FontAwesomeIcon icon={faEdit} className='me-2' /> Edit Lesson
            </button>
          </td>
        )}

        <td>
          <button
            onClick={() => {
              setIDF(_id)
            }}
            className='btn btn-danger'
            data-toggle='modal'
            data-target='#lessonDelete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
          </button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <div
        className='modal fade'
        id='lessonDelete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='lessonDelete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Lesson Name</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Edit Lesson</th>}

                <th className='border-bottom'>Remove Lesson</th>
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}

              {/* {courses.map(res=>(
              <p>{res.nameOfCourse}</p>
            ))} */}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
            {/* <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>
                  Previous
                </Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav> */}
            {/* <small className="fw-bold">
              Showing <b>{totalTransactions}</b> out of <b>25</b> entries
            </small> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const QuizTable = props => {
  const [courses, setCourses] = useState([])
  const lessonId = props.chapterid

  // const [alerts, setAlert] = useState(false)

  const [idf, setIdf] = useState('')
  const { user } = useUserContext()

  useEffect(() => {
    Refresh()
  }, [])
  async function Refresh() {
    await axios
      .get(`/api/free-lesson-quiz/list-by-chapter/${lessonId}`)
      .then(res => {
        console.log('res?.data :', res?.data)
        setCourses(res?.data?.data?.[0]?.questions)

        // console.log(res)
      })
      .catch(() => setCourses([]))
  }

  async function Delete() {
    await axios
      .delete(`/api/free-lesson-quiz/delete/${lessonId}`)
      .then(() => {
        toast.success('Question deleted Successfully!')

        // setAlert(false)
        Refresh()
      })
      .catch(e => toast.error(e?.message ?? 'Question not deleted'))
  }

  const TableRow = props => {
    const { _id, type, question, answer } = props
    // const statusVariant = status === "Paid" ? "success"
    //   : status === "Due" ? "warning"
    //     : status === "Canceled" ? "danger" : "primary";

    return (
      <tr>
        <td>
          <span className='fw-normal'>{question}</span>
        </td>
        <td>
          <span className='fw-normal'>{type === true ? 'True/False' : 'MCQs'}</span>
        </td>
        <td>
          <span className='fw-normal'>{answer}</span>
        </td>

        {user.role === 'super-admin' && (
          <td>
            <button
              onClick={() => (window.location.href = `#/free-course/edit-quiz/` + lessonId + '/' + _id)}
              className='btn '
            >
              <FontAwesomeIcon icon={faAd} className='me-2' /> Edit Question
            </button>
          </td>
        )}
        {user.role === 'super-admin' && (
          <td>
            <button
              onClick={() => {
                // setAlert(true)
                setIdf(_id)
              }}
              className='btn btn-danger'
              data-toggle='modal'
              data-target='#quizDelete'
            >
              <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove Question
            </button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      {/* {alerts === false ? (
          <></>
        ) : (
          <div className='alert-box'>
            <div className='container'>
              <p>Are you sure want to delete ?</p>
              <div className='buttons'>
                <div className='yes' onClick={() => {}}>
                  {' '}
                  Yes{' '}
                </div>
                <div className='no' onClick={() => setAlert(false)}>
                  {' '}
                  Cancel
                </div>
              </div>
            </div>
          </div>
        )} */}

      <div
        className='modal fade'
        id='quizDelete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='quizDelete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom'>Quiz Name</th>
                <th className='border-bottom'>Type</th>
                <th className='border-bottom'>Answer</th>
                {user.role === 'super-admin' && <th className='border-bottom'>Edit Quiz</th>}
                {user.role === 'super-admin' && <th className='border-bottom'>Remove Quiz</th>}
              </tr>
            </thead>
            <tbody>
              {courses?.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}

              {/* {courses.map(res=>(
              <p>{res.nameOfCourse}</p>
            ))} */}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'>
            {/* <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>
                  Previous
                </Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav> */}
            {/* <small className="fw-bold">
              Showing <b>{totalTransactions}</b> out of <b>25</b> entries
            </small> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const QuizFormTable = () => {
  const [courses, setCourses] = useState([])

  useEffect(() => {
    Refresh()
  }, [])

  async function Refresh() {
    await callApi({ uriEndPoint: quizPoint })
      .then(res => {
        const filter = res.filter(i => i.totalPercentage !== null)
        if (res) {
          setCourses(filter)
        }
      })
      .catch(e => console.log(e))
  }

  const TableRow = props => {
    const { courseName, studentName, totalPercentage, totalScore, totalQuestion } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{courseName}</span>
        </td>
        <td>
          <span className='fw-normal'>{studentName}</span>
        </td>
        <td>
          <span className='fw-normal'>{totalScore + '/' + totalQuestion}</span>
        </td>
        <td>
          <span className='fw-normal'>{totalPercentage.toFixed(2)}</span>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  Course Name
                </th>
                <th className='border-bottom'>Student Name</th>
                <th className='border-bottom'>Quiz Score</th>

                {<th className='border-bottom'>Percentage</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t._id}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const UniversityFormTable = () => {
  const [courses, setCourses] = useState([])
  const history = useHistory()
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()
  const [updateStudentStatus, setUpdateStudentStatus] = useState(null)

  async function StatusUpdateUsers({ id, value }) {
    const body = { status: value }
    await callApi({ uriEndPoint: universityStatusUpdate, pathParams: { id: id }, body })
      .then(res => {
        setRecallApi(Math.floor(Math.random(1) * 100).toString())
        toast.success(res?.message ?? 'University status updated successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'University status not updates!'))
  }

  //   async function deleteUsers() {
  //     await callApi({ uriEndPoint: studentDelete, pathParams: { id: id } })
  //       .then(() => {
  //         setRecallApi(Math.floor(Math.random(1) * 100).toString())
  //         setUpdateStudentStatus(null)
  //       })
  //       .catch(e => console.log(e))
  //   }

  const handelOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: universityListEndPoint })
      .then(res => {
        if (res?.allUniversity) {
          setCourses(res?.allUniversity)
        }
      })
      .catch(e => console.log(e))
  }

  const questionPage = _id => {
    history.push(`/update-university/${_id}`)
  }

  const deleteUniversity = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: deleteUniversityEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())

        toast.success(res?.message ?? 'University deleted Successfully!')

        handelOpen()
      })

      .catch(e => toast.error(e?.message ?? 'University not deleted!'))
  }

  const TableRow = props => {
    const { name, status, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>

        <td>
          <button onClick={() => (window.location.href = `#/university-college/` + _id)} className='btn'>
            <FontAwesomeIcon icon={faEye} className='me-2' /> View Colleges
          </button>
        </td>

        <td>
          <Form.Check // prettier-ignore
            type='switch'
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={() => questionPage(_id)}>
              Edit
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteUniversity(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  University
                </th>

                <th className='border-bottom'>Colleges</th>

                <th className='border-bottom'>Status</th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='exampleModalCenterStatus'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure you want to update status?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => StatusUpdateUsers({ id: updateStudentStatus?.id, value: updateStudentStatus?.value })}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const UniversityCollegeTable = ({ colleges, recallApi }) => {
  const [editOpen, setEditOpen] = useState(null)
  const [collegeName, setCollegeName] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()
  const [updateStudentStatus, setUpdateStudentStatus] = useState(null)

  async function StatusUpdateUsers({ id, value }) {
    const body = { status: value }
    await callApi({ uriEndPoint: collegeStatusUpdate, pathParams: { id: id }, body })
      .then(res => {
        recallApi()
        toast.success(res?.message ?? 'College status updated successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'College status not updates!'))
  }

  const handelOpen = () => {
    setOpen(!open)
    recallApi()
  }

  const deleteUniversity = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: deleteCollegeEndPoint, pathParams: { id: id || null } })
      .then(res => {
        recallApi()
        toast.success(res?.message ?? 'College deleted Successfully!')

        handelOpen()
      })

      .catch(e => toast.error(e?.message ?? 'College not deleted!'))
  }

  const handleSubmit = async () => {
    if (collegeName === '') {
      alert('College name cannot be empty')
      return
    }

    const body = {
      college_name: collegeName
    }

    await callApi({ uriEndPoint: collegeUpdateEndPoint, pathParams: { id: editOpen }, body })
      .then(() => {
        recallApi()
        setEditOpen(!editOpen)
        toast.success('College updated successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'College not updated'))
    setEditOpen(null)
  }

  const TableRow = props => {
    const { college_name, status, _id } = props

    const handleEdit = () => {
      setEditOpen(_id)
      setCollegeName(college_name)
    }

    return (
      <tr>
        <td>
          <span className='fw-normal'>{college_name}</span>
        </td>

        <td>
          <Form.Check // prettier-ignore
            type='switch'
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={handleEdit}>
              Edit
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteUniversity(_id)}
              data-toggle='modal'
              variant='danger'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  College
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Status
                </th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {colleges?.length ? (
                colleges.map(t => <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />)
              ) : (
                <td colSpan={3}>
                  <div className='d-flex justify-content-center'>Data not found</div>
                </td>
              )}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='exampleModalCenterStatus'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure you want to update status?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => StatusUpdateUsers({ id: updateStudentStatus?.id, value: updateStudentStatus?.value })}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={!!editOpen} onHide={() => setEditOpen(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit college</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter name of the college :</p>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Control
                required
                type='text'
                placeholder='College Name'
                value={collegeName}
                onChange={e => setCollegeName(e?.target?.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' primary onClick={() => setEditOpen(null)}>
            Cancel
          </Button>
          <Button variant='secondary' onClick={handleSubmit} disabled={!collegeName?.length}>
            Update
          </Button>
        </Modal.Footer>{' '}
      </Modal>
    </>
  )
}

export const CoordinatorFormTable = () => {
  const [courses, setCourses] = useState([])
  const history = useHistory()
  const [recallApi, setRecallApi] = useState('')
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const { user } = useUserContext()
  const [updateStudentStatus, setUpdateStudentStatus] = useState(null)

  const [addFiled, setAddFiled] = useState({
    password: '',
    confirmPassword: '',
    error: ''
  })

  const [showPassword, setShowPassword] = useState(false)
  const [errorHandel, setErrorHandel] = useState(false)
  const [updatePassWordModel, setUpdatePassWordModel] = useState(false)

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  const updatePassWord = async props => {
    handelOpenPassWordUpdate()
    setId(props)
  }

  const handelOpenPassWordUpdate = () => {
    setUpdatePassWordModel(!updatePassWordModel)
  }

  const cancelPassword = () => {
    setAddFiled({})
    setId('')
  }

  // const confirmPasswordClick = async () => {
  //   // Reset previous error state
  //   setErrorHandel(false)

  //   // Password validation
  //   if (!addFiled.password || !addFiled.confirmPassword) {
  //     setErrorHandel(true)
  //     setAddFiled(prev => ({ ...prev, error: 'Both password fields are required.' }))
  //     return
  //   }

  //   // Check if passwords match
  //   if (addFiled.password !== addFiled.confirmPassword) {
  //     setErrorHandel(true)
  //     setAddFiled(prev => ({ ...prev, error: 'Passwords do not match.' }))
  //     return
  //   }

  //   // Password validation rules
  //   const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/ // At least 8 chars, 1 letter, 1 number, 1 special char
  //   if (!passwordRegex.test(addFiled.password)) {
  //     setErrorHandel(true)
  //     setAddFiled(prev => ({
  //       ...prev,
  //       error:
  //         'Password must be at least 8 characters long and contain at least 1 letter, 1 number, and 1 special character.'
  //     }))
  //     return
  //   }

  //   // If everything is valid, make the API call
  //   await callApi({ uriEndPoint: coachPasswordUpdateEndPoint, body: addFiled, pathParams: { id: id._id || null } })
  //     .then(res => {
  //       alert(res?.message || 'Password updated successfully')
  //       handelOpenPassWordUpdate()
  //       setId('')
  //     })
  //     .catch(e => console.log(e))
  // }

  const confirmPasswordClick = async () => {
    // Reset previous error state

    setErrorHandel(false)

    // Password validation
    if (!addFiled.password || !addFiled.confirmPassword) {
      setErrorHandel(true)
      setAddFiled(prev => ({ ...prev, error: 'Both password fields are required.' }))
      return
    }

    // Check if passwords match
    if (addFiled.password !== addFiled.confirmPassword) {
      setErrorHandel(true)
      setAddFiled(prev => ({ ...prev, error: 'Passwords do not match.' }))
      return
    }

    // Password validation rules
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/ // At least 8 chars, 1 letter, 1 number, 1 special char
    if (!passwordRegex.test(addFiled.password)) {
      setErrorHandel(true)
      setAddFiled(prev => ({
        ...prev,
        error:
          'Password must be at least 8 characters long and contain at least 1 letter, 1 number, and 1 special character.'
      }))
      return
    }

    // If everything is valid, make the API call
    try {
      const res = await callApi({
        uriEndPoint: coachPasswordUpdateEndPoint,
        body: addFiled,
        pathParams: { id: id._id || null }
      })

      toast.success(res?.message ?? 'Password updates Successfully!')

      // Close the modal after successful API call
      setUpdatePassWordModel(false) // Close the modal by toggling the state

      // Reset ID and form fields
      setId('')
      setAddFiled({ password: '', confirmPassword: '', error: '' })
    } catch (e) {
      toast.error(e?.message ?? 'Password not updated!')
    }
  }

  async function StatusUpdateUsers({ id, value }) {
    const body = { status: value }
    await callApi({ uriEndPoint: coordinatorStatusUpdate, pathParams: { id: id }, body })
      .then(res => {
        setRecallApi(Math.floor(Math.random(1) * 100).toString())
        toast.success(res?.message ?? 'Coordinator status updated successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'Coordinator status not updates!'))
  }

  //   async function deleteUsers() {
  //     await callApi({ uriEndPoint: studentDelete, pathParams: { id: id } })
  //       .then(() => {
  //         setRecallApi(Math.floor(Math.random(1) * 100).toString())
  //         setUpdateStudentStatus(null)
  //       })
  //       .catch(e => console.log(e))
  //   }

  const handelOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    Refresh()
  }, [recallApi])

  async function Refresh() {
    await callApi({ uriEndPoint: coordinatorListEndPoint })
      .then(res => {
        if (res?.allCoordinator) {
          setCourses(res?.allCoordinator)
        }
      })
      .catch(e => console.log(e))
  }

  const questionPage = _id => {
    history.push(`/update-coordinator/${_id}`)
  }

  const deleteUniversity = async id => {
    handelOpen()
    setId(id)
  }

  const confirmClick = async () => {
    await callApi({ uriEndPoint: deleteCoordinatorEndPoint, pathParams: { id: id || null } })
      .then(res => {
        setRecallApi(Math.floor(Math.random(0, 1) * 100).toString())
        toast.success(res?.message ?? 'Coordinator deleted Successfully!')
        handelOpen()
      })
      .catch(e => toast.error(e?.message ?? 'Coordinator not deleted!'))
  }

  const TableRow = props => {
    const { name, phone, email, _id } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{name}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <Form.Check // prettier-ignore
            type='switch'
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td>

        {user.role === 'super-admin' && (
          <td>
            <Button style={{ marginLeft: '5px' }} onClick={() => questionPage(_id)}>
              Edit
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => deleteUniversity(_id)}
              data-toggle='modal'
              data-target='#exampleModalCenter'
            >
              Delete
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => updatePassWord(props)}
              data-toggle='modal'
              data-target='#exampleModalCenter-password-1'
            >
              Update Password
            </Button>
          </td>
        )}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th className='border-bottom ' style={{ minWidth: '300px' }}>
                  University
                </th>
                <th className='border-bottom'>Phone Number</th>
                <th className='border-bottom' style={{ minWidth: '200px' }}>
                  Email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th>

                {user.role === 'super-admin' && <th className='border-bottom'>Action</th>}
              </tr>
            </thead>
            <tbody>
              {courses.map(t => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div
        className='modal fade'
        id='exampleModalCenter'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => confirmClick()}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='exampleModalCenterStatus'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure you want to update status?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => StatusUpdateUsers({ id: updateStudentStatus?.id, value: updateStudentStatus?.value })}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for password update */}
      <div
        className={`modal fade ${updatePassWordModel ? 'show' : ''}`}
        id='exampleModalCenter-password-1'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure? {id?.email || ''}
              </h5>
            </div>

            <div className='row p-4'>
              <div className='form-group col-md-12'>
                <label htmlFor='password'>Update Password*</label>
                <div className='d-flex align-items-center position-relative'>
                  <input
                    autoComplete='false'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    value={addFiled?.password || ''}
                    onChange={handelChange}
                    className='form-control'
                    id='password'
                    placeholder='Password'
                  />
                  <span
                    className='eye-icon position-absolute'
                    style={{ right: '10px', cursor: 'pointer' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <i className='fa-solid fa-eye'></i> : <i className='fa-solid fa-eye-slash'></i>}
                  </span>
                </div>
              </div>
              <div className='form-group col-md-12'>
                <label htmlFor='confirmPassword'>Confirm Password*</label>
                <input
                  type='password'
                  name='confirmPassword'
                  value={addFiled?.confirmPassword || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='confirmPassword'
                  placeholder='Confirm Password'
                />
              </div>

              {errorHandel && (
                <h5 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>
                  * {addFiled?.error}
                </h5>
              )}
            </div>

            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => confirmPasswordClick()}
              >
                Yes, update it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal' onClick={() => cancelPassword()}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const StudentsTable = props => {
  const selectedStudentIds = props.selectedStudentIds || []
  const history = useHistory()
  // const [updateStudentStatus, setUpdateStudentStatus] = useState(null)
  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    // If the length of selectedStudentIds matches approveStudentsIds, deselect all, else select all
    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      // Deselect all students if already all are selected
      props.setSelectedStudentIds([])
    } else {
      // Select all approved students
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  // Determine if "Select All" should be checked
  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const studentViewPage = _id => {
    history.push(`assign-course/student-view/${_id}`)
  }

  const TableRow = props => {
    const { _id, student_id, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(_id)}
            value={selectedStudentIds?.includes(_id)}
            onChange={() => handleStudentSelect(_id)}
            // disabled={approval_status !== 'approve'}
          />

          {/* <Form.Check
            type='checkbox'
            checked={(props.selectedStudentIds || []).includes(_id)} // Safe check for undefined
            value={_id} // Use the student ID as the value
            onChange={() => handleStudentSelect(_id)}
            disabled={approval_status !== 'approve'}
          /> */}
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_id ? student_id : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        {/* <td>
          <Form.Check // prettier-ignore
            type='switch'
            disabled
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td> */}

        <td className=''>
          <button
            onClick={() => {
              setIDF(_id)
            }}
            className='btn btn-danger me-2'
            data-toggle='modal'
            data-target='#free-course-delete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='' />
          </button>
          <Button onClick={() => studentViewPage(_id)} className='btn btn-pr'>
            <FontAwesomeIcon icon={faEye} className='' />
          </Button>
        </td>
      </tr>
    )
  }

  const [idf, setIDF] = useState('')

  function Delete(id) {
    axios
      .delete(`/api/free-course-user/delete/${id}`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          toast.success('Student deleted Successfully!')
          props.StudentListData()

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Student not deleted')
      })
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  {/* <input
                    type='checkbox'
                    onChange={handleSelectAll}
                    checked
                    // checked={selectedStudentIds.length === approveStudentIds.length}
                  /> */}

                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Id
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {props?.studentList?.length !== 0 ? (
                props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>
            {/* {props?.studentList?.length !== 0 ? (
              <tbody>
                {props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))}
              </tbody>
            ) : (
              <tbody className='d-flex justify-content-center align-items-center'>
                <TableRow>
                  <td colSpan={8}>Data not found</td>
                </TableRow>
              </tbody>
            )} */}
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>
      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const StudentsAssignCourseTable = props => {
  const selectedStudentIds = props.selectedStudentIds || []

  const [id, setId] = useState('')
  // const [updateStudentStatus, setUpdateStudentStatus] = useState(null)
  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const handleClose = () => setShowModal(false)

  const handleShow = id => {
    setId(id)
    setShowModal(true) // Open the modal when the button is clicked
  }

  async function approveStatus() {
    let body = { approval_status: selectedApproveStatus, students: [id] }

    await callApi({ uriEndPoint: studentApproveStatusUpdate, body })
      .then(() => {
        props?.setRecallApi(Math.floor(Math.random(1) * 100).toString())
        setShowModal(!showModal)
        setSelectedApproveStatus(null)
        toast.success('Student status change Successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'Student status not change'))
  }

  const handelChange = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    // If the length of selectedStudentIds matches approveStudentsIds, deselect all, else select all
    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      // Deselect all students if already all are selected
      props.setSelectedStudentIds([])
    } else {
      // Select all approved students
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  // Determine if "Select All" should be checked
  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const TableRow = props => {
    const { _id, email, phone, index, created_at, approval_status, student_id } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(_id)}
            value={selectedStudentIds?.includes(_id)}
            onChange={() => handleStudentSelect(_id)}
            // disabled={approval_status !== 'approve'}
          />
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_id ? student_id : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>

        <td>
          <Button
            data-toggle='modal'
            data-target='#exampleModalCenter'
            className='text-capitalize'
            onClick={() => handleShow(_id)}
          >
            {approval_status === 'approve' ? 'approved' : approval_status}
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm mt-2'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Id
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Registration Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>

            <tbody>
              {props?.studentList?.length !== 0 ? (
                props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    No new student record found
                  </td>
                </tr>
              )}
            </tbody>
            {/* {props?.studentList?.length !== 0 ? (
              <tbody>
                {props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))}
              </tbody>
            ) : (
              <tbody className='d-flex justify-content-center align-items-center'>
                <TableRow>
                  <td colSpan={8}>Data not found</td>
                </TableRow>
              </tbody>
            )} */}
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will change the approval status of student!</p>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChange}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approved</option>
                <option value='cancel'>Cancelled</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='secondary' onClick={approveStatus} disabled={!selectedApproveStatus}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export const AssignBadgesTable = props => {
  const selectedStudentIds = props.selectedStudentIds || []

  // Function to determine the color of the progress bar based on the score
  const getProgressBarVariant = score => {
    if (score >= 90) return 'success' // Green
    if (score >= 80) return 'warning' // Orange
    return 'danger' // Red
  }

  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.studentList
      .filter(student => student?.student_details?.approval_status === 'approve')
      .map(student => student?.student_details?._id)

    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      props.setSelectedStudentIds([])
    } else {
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.studentList
      .filter(student => student?.student_details?.approval_status === 'approve')
      .map(student => student?.student_details?._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const TableRow = props => {
    const { index } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(props?.student_details?._id)}
            value={selectedStudentIds?.includes(props?.student_details?._id)}
            onChange={() => handleStudentSelect(props?.student_details?._id)}
            disabled={props?.student_details?.approval_status !== 'approve'}
          />
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>
            {props?.student_details?.student_id ? props?.student_details?.student_id : '-'}
          </span>
        </td>
        <td>
          <span className='fw-normal'>{props?.student_details?.email}</span>
        </td>
        <td>
          <span className='fw-normal'>
            {props?.student_details?.created_at
              ? moment(props?.student_details?.created_at).format('DD-MMM-YYYY')
              : '-'}
          </span>
        </td>
        <td>
          <span className='fw-normal'>{props?.student_details?.phone}</span>
        </td>
        <td>
          {props?.watchPercentage !== undefined ? (
            <ProgressBar
              now={props?.watchPercentage}
              label={`${props?.watchPercentage}%`}
              variant={getProgressBarVariant(props?.watchPercentage)}
              // animated
              style={{ height: '20px', borderRadius: '20px' }}
            />
          ) : (
            '-'
          )}
        </td>

        <td>
          <button
            onClick={() => {
              setIDF(props?.student_details?._id)
            }}
            className='btn btn-danger'
            data-toggle='modal'
            data-target='#free-course-delete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
          </button>
        </td>
      </tr>
    )
  }

  const [idf, setIDF] = useState('')

  function Delete(id) {
    axios
      .delete(`/api/free-course-user/delete/${id}`)
      .then(res => {
        console.log('started')
        if (res.data.success === true) {
          toast.success('Student deleted Successfully!')
          props.StudentListData()

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Student not deleted')
      })
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Id
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Score
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {props?.studentList?.length !== 0 ? (
                props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>

      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>
      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const AssignCourseFacultyTable = props => {
  const selectedStudentIds = props.selectedStudentIds || []

  const [id, setId] = useState('')
  // const [updateStudentStatus, setUpdateStudentStatus] = useState(null)

  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const handleClose = () => setShowModal(false)

  const handleShow = id => {
    setId(id)
    setShowModal(true) // Open the modal when the button is clicked
  }

  async function approveStatus() {
    let body = { approval_status: selectedApproveStatus, faculties: [id] }

    await callApi({ uriEndPoint: facultyApproveStatusUpdate, body })
      .then(() => {
        props?.setRecallApi(Math.floor(Math.random(1) * 100).toString())
        setShowModal(!showModal)
        setSelectedApproveStatus(null)
      })
      .catch(e => console.log(e))
  }

  const handelChange = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.facultyList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    // If the length of selectedStudentIds matches approveStudentsIds, deselect all, else select all
    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      // Deselect all students if already all are selected
      props.setSelectedStudentIds([])
    } else {
      // Select all approved students
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  // Determine if "Select All" should be checked
  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.facultyList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const TableRow = props => {
    const { _id, full_name, email, phone, index, created_at, approval_status } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(_id)}
            value={selectedStudentIds?.includes(_id)}
            onChange={() => handleStudentSelect(_id)}
            // disabled={approval_status !== 'approve'}
          />

          {/* <Form.Check
            type='checkbox'
            checked={(props.selectedStudentIds || []).includes(_id)} // Safe check for undefined
            value={_id} // Use the student ID as the value
            onChange={() => handleStudentSelect(_id)}
            disabled={approval_status !== 'approve'}
          /> */}
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{full_name ? full_name : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        {/* <td>
          <Form.Check // prettier-ignore
            type='switch'
            disabled
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td> */}

        <td>
          <Button data-toggle='modal' data-target='#exampleModalCenter' onClick={() => handleShow(_id)}>
            {approval_status}
          </Button>
          {/* <Button onClick={() => StatusUpdateUsers(props)}>Update Status</Button> */}
        </td>
        <td>
          <button
            onClick={() => {
              setIDF(_id)
            }}
            className='btn btn-danger'
            data-toggle='modal'
            data-target='#free-course-delete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='me-2' /> Remove
          </button>
        </td>
      </tr>
    )
  }

  const [idf, setIDF] = useState('')

  function Delete(id) {
    axios
      .delete(`/api/faculty/delete/${id}`)
      .then(res => {
        if (res.data.success === true) {
          toast.success('Faculty deleted Successfully!')
          props?.setRecallApi(Math.floor(Math.random(1) * 100).toString())

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Faculty not deleted')
      })
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  {/* <input
                    type='checkbox'
                    onChange={handleSelectAll}
                    checked
                    // checked={selectedStudentIds.length === approveStudentIds.length}
                  /> */}

                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Legal name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Status
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {props?.facultyList?.length !== 0 ? (
                props?.facultyList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>

            {/* {props?.facultyList?.length !== 0 ? (
              <tbody>
                {props?.facultyList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))}
              </tbody>
            ) : (
              <tbody className='d-flex justify-content-center align-items-center'>
                <TableRow>
                  <td colSpan={8}>Data not found</td>
                </TableRow>
              </tbody>
            )} */}
          </Table>
        </Card.Body>
      </Card>

      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>

      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChange}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approve</option>
                <option value='pending'>Pending</option>
                <option value='cancel'>Cancel</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={approveStatus} disabled={!selectedApproveStatus}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const StudentsReportTable = props => {
  // const [courses, setCourses] = useState([])
  // // const [recallApi, setRecallApi] = useState('')

  const TableRow = props => {
    const { full_name, email, phone, index, created_at } = props

    return (
      <tr>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{full_name ? full_name : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>

        {/* <td>
          <Button data-toggle='modal' data-target='#exampleModalCenter' onClick={() => setId(_id)}>
            View
          </Button>
        </td> */}
      </tr>
    )
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Full name
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>
              {props?.courses?.map((t, index) => (
                <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
              ))}
            </tbody>
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

export const PromoteStudentTable = props => {
  const selectedStudentIds = props.selectedStudentIds || []
  const history = useHistory()
  // const [updateStudentStatus, setUpdateStudentStatus] = useState(null)
  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    // If the length of selectedStudentIds matches approveStudentsIds, deselect all, else select all
    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      // Deselect all students if already all are selected
      props.setSelectedStudentIds([])
    } else {
      // Select all approved students
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  // Determine if "Select All" should be checked
  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const studentViewPage = _id => {
    history.push(`assign-course/student-view/${_id}`)
  }

  const TableRow = props => {
    const { _id, student_id, email, phone, index, created_at, pursuing_year } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(_id)}
            value={selectedStudentIds?.includes(_id)}
            onChange={() => handleStudentSelect(_id)}
            // disabled={approval_status !== 'approve'}
          />

          {/* <Form.Check
            type='checkbox'
            checked={(props.selectedStudentIds || []).includes(_id)} // Safe check for undefined
            value={_id} // Use the student ID as the value
            onChange={() => handleStudentSelect(_id)}
            disabled={approval_status !== 'approve'}
          /> */}
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_id ? student_id : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{pursuing_year ? pursuing_year : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>
        <td>
          <span className='fw-normal'>{created_at ? moment(created_at).format('DD-MMM-YYYY') : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        {/* <td>
          <Form.Check // prettier-ignore
            type='switch'
            disabled
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td> */}

        <td className=''>
          <button
            onClick={() => {
              setIDF(_id)
            }}
            className='btn btn-danger me-2'
            data-toggle='modal'
            data-target='#free-course-delete'
          >
            <FontAwesomeIcon icon={faTrashAlt} className='' />
          </button>
          <Button onClick={() => studentViewPage(_id)} className='btn btn-pr'>
            <FontAwesomeIcon icon={faEye} className='' />
          </Button>
        </td>
      </tr>
    )
  }

  const [idf, setIDF] = useState('')

  function Delete(id) {
    axios
      .delete(`/api/free-course-user/delete/${id}`)
      .then(res => {
        if (res.data.success === true) {
          toast.success('Student deleted Successfully!')
          props.StudentListData()

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Student not deleted')
      })
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  {/* <input
                    type='checkbox'
                    onChange={handleSelectAll}
                    checked
                    // checked={selectedStudentIds.length === approveStudentIds.length}
                  /> */}

                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Id
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Pursuing Year
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Date
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {props?.studentList?.length !== 0 ? (
                props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>
            {/* {props?.studentList?.length !== 0 ? (
              <tbody>
                {props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))}
              </tbody>
            ) : (
              <tbody className='d-flex justify-content-center align-items-center'>
                <TableRow>
                  <td colSpan={8}>Data not found</td>
                </TableRow>
              </tbody>
            )} */}
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>
      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const StudentsTableFreeCourse = props => {
  const selectedStudentIds = props.selectedStudentIds || []
  // const [updateStudentStatus, setUpdateStudentStatus] = useState(null)
  const handlePageChange = newPage => {
    props?.setCurrentPage(newPage)
  }

  const totalPages = Math.ceil(props?.totalItems / props?.itemsPerPage)

  const handleSelectAll = () => {
    // Get the IDs of all students who are approved
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    // If the length of selectedStudentIds matches approveStudentsIds, deselect all, else select all
    const selectedStudentIds = props.selectedStudentIds || []
    if (selectedStudentIds.length === approveStudentsIds.length) {
      // Deselect all students if already all are selected
      props.setSelectedStudentIds([])
    } else {
      // Select all approved students
      props.setSelectedStudentIds(approveStudentsIds)
    }
  }

  // Determine if "Select All" should be checked
  const isSelectAllChecked = () => {
    const approveStudentsIds = props?.studentList
      // .filter(student => student.approval_status === 'approve')
      .map(student => student._id)

    const selectedStudentIds = props.selectedStudentIds || []
    return selectedStudentIds.length === approveStudentsIds.length
  }

  // Toggle selection for individual student
  const handleStudentSelect = id => {
    props.setSelectedStudentIds(prev => (prev.includes(id) ? prev.filter(sid => sid !== id) : [...prev, id]))
  }

  const TableRow = props => {
    const { _id, student_id, email, phone, index } = props

    return (
      <tr>
        <td>
          <Form.Check
            type='checkbox'
            checked={selectedStudentIds?.includes(_id)}
            value={selectedStudentIds?.includes(_id)}
            onChange={() => handleStudentSelect(_id)}
            // disabled={approval_status !== 'approve'}
          />
        </td>
        <td>
          <span className='fw-normal'>{index + 1}</span>
        </td>
        <td>
          <span className='fw-normal'>{student_id ? student_id : '-'}</span>
        </td>
        <td>
          <span className='fw-normal'>{email}</span>
        </td>

        <td>
          <span className='fw-normal'>{phone}</span>
        </td>
        {/* <td>
          <Form.Check // prettier-ignore
            type='switch'
            disabled
            id='custom-switch'
            data-toggle='modal'
            data-target='#exampleModalCenterStatus'
            checked={props.status}
            onChange={e => {
              setUpdateStudentStatus({ id: props._id, value: e.target.checked })
            }}
          />
        </td> */}
      </tr>
    )
  }

  const [idf, setIDF] = useState('')

  function Delete(id) {
    axios
      .delete(`/api/free-course-user/delete/${id}`)
      .then(res => {
        if (res.data.success === true) {
          toast.success('Student deleted Successfully!')
          props.StudentListData()

          setIDF('')
        }
      })
      .catch(e => {
        toast.error(e?.message ?? 'Student not deleted')
      })
  }

  return (
    <>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body style={{ height: 432 }} className='pt-0'>
          <Table hover className='user-table align-items-center'>
            <thead>
              <tr>
                <th>
                  {/* <input
                    type='checkbox'
                    onChange={handleSelectAll}
                    checked
                    // checked={selectedStudentIds.length === approveStudentIds.length}
                  /> */}

                  <Form.Check type='checkbox' checked={isSelectAllChecked()} onChange={handleSelectAll} />
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  No.
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  Student Id
                </th>
                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  email
                </th>

                <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  phone{' '}
                </th>
                {/* <th
                  className='border-bottom'
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: 'white',
                    zIndex: 1,
                    borderBottom: '2px solid #dee2e6'
                  }}
                >
                  status
                </th> */}
              </tr>
            </thead>

            <tbody>
              {props?.studentList?.length !== 0 ? (
                props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))
              ) : (
                <tr>
                  <td colSpan={8} className='text-center self-align-center'>
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>
            {/* {props?.studentList?.length !== 0 ? (
              <tbody>
                {props?.studentList.map((t, index) => (
                  <TableRow key={`transaction-${t.invoiceNumber}`} {...t} index={index} />
                ))}
              </tbody>
            ) : (
              <tbody className='d-flex justify-content-center align-items-center'>
                <TableRow>
                  <td colSpan={8}>Data not found</td>
                </TableRow>
              </tbody>
            )} */}
          </Table>
          <Card.Footer className='px-3 border-0 d-lg-flex align-items-center justify-content-between'></Card.Footer>
        </Card.Body>
      </Card>
      <div className='px-3 border-0 d-lg-flex align-items-center justify-content-end mt-2'>
        <div className='pagination d-flex justify-content-center align-items-center '>
          <div className='items-per-page-selector me-5'>
            <label className='pe-2'>Items per page: </label>
            <select value={props.itemsPerPage} onChange={props.handleItemsPerPageChange} className='mr-2'>
              <option value={1}>1</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className='mr-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage - 1)}
            disabled={props?.currentPage === 1}
          />
          <span className='mx-2'>
            Page {props?.currentPage} of {totalPages}
          </span>
          <FontAwesomeIcon
            icon={faChevronRight}
            className='me-2 cursor-pointer-class  '
            onClick={() => handlePageChange(props?.currentPage + 1)}
            disabled={props?.currentPage >= totalPages}
          />
        </div>
      </div>
      <div
        className='modal fade'
        id='free-course-delete'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='free-course-delete'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  w-100 justify-content-center'>
              <h5 className='modal-title' id='exampleModalLongTitle'>
                Are you sure?
              </h5>
            </div>
            <div className='modal-body align-self-center'>You won't be able to revert this!</div>
            <div className='modal-footer justify-content-center'>
              <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={() => Delete(idf)}>
                Yes, delete it!
              </button>
              <button type='button' className='btn btn-primary' data-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
