import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Breadcrumb, Card, Col, Image, Pagination, Row, Tab, Table, Tabs } from '@themesberg/react-bootstrap'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { callApi } from '../../../../utils/api-utils'
import { awsDownload } from '../../../../utils/aws'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const RenderImage = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [imageSignedUrl, setImageSignedUrl] = useState(null)

  const generateSignedUrl = async () => {
    setIsLoading(true)
    try {
      const filePath = props?.src // Replace with your S3 file path
      const duration = 300 // Optional duration in seconds (default is 60 seconds)
      const signedUrl = await awsDownload(filePath, duration)

      setImageSignedUrl(signedUrl)
    } catch (error) {
      console.log('Error:', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    generateSignedUrl()
  }, [])

  return <Image {...props} src={isLoading ? 'src/assets/img/technologies/react-hero-logo.svg' : imageSignedUrl} />
}

const studentViewApi = {
  ...defaults.methods.GET,
  uri: '/api/free-course-user/view-free-user'
}

const collegeListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/college/list'
}

function StudentViewPage(props) {
  const [studentViewData, setStudentViewData] = useState(null)
  const [colleges, setColleges] = useState([])

  useEffect(() => {
    callApi({ uriEndPoint: studentViewApi, query: { student_id: props?.match?.params?.id } }).then(res => {
      setStudentViewData(res?.data)
    })
  }, [])

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 5

  // Calculate paginated records
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = studentViewData?.login_history?.slice(indexOfFirstRecord, indexOfLastRecord) ?? []

  // Function to handle page change
  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const totalPages = Math.ceil(studentViewData?.login_history?.length / recordsPerPage) ?? []

  const academicYearData = useMemo(() => [
    { u_id: 1, value: '1', label: '1st year' },
    { u_id: 2, value: '2', label: '2nd year' },
    { u_id: 3, value: '3', label: '3rd year' },
    { u_id: 4, value: '4', label: '4th year' },
    { u_id: 5, value: '5', label: 'Internship' },
    { u_id: 6, value: '6', label: 'MD Part 1' },
    { u_id: 7, value: '7', label: 'MD Part 2' }
  ])

  const getPursuingYearTitle = useCallback((pur_year = studentViewData?.student_data?.pursuing_year) => {
    return academicYearData.find(v => v.value === pur_year)?.label
  })

  const getCollegeTitle = useCallback((clg_name = studentViewData?.student_data?.collage_id) => {
    return colleges.find(v => v._id === clg_name)?.college_name
  })

  async function Refresh() {
    await callApi({
      uriEndPoint: collegeListEndPoint,
      query: { university_id: studentViewData?.student_data?.university_id }
    })
      .then(res => {
        if (res?.allCollege) {
          setColleges(res?.allCollege ?? [])
        } else {
          setColleges([])
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    Refresh()
  }, [])

  return (
    <div>
      <Breadcrumb
        className='d-none d-md-inline-block'
        listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
      >
        <Breadcrumb.Item>
          <FontAwesomeIcon icon={faHome} />
        </Breadcrumb.Item>
        <Breadcrumb.Item>SHAH</Breadcrumb.Item>
        <Breadcrumb.Item href='/#/assign-course'>All Students</Breadcrumb.Item>
        <Breadcrumb.Item active>Student View</Breadcrumb.Item>
        <Breadcrumb.Item active>{props?.match?.params?.id}</Breadcrumb.Item>
      </Breadcrumb>
      <div className='mt-4'>
        <Row className='mb-4'>{/* Student Details Grid (4 Columns) */}</Row>

        {/* Tabs Section (8 Columns) */}
        <Row>
          <Col md={4} lg={4}>
            <Card className='shadow-sm' style={{ height: '100%' }}>
              <Card.Body>
                <Card.Title>Student Details</Card.Title>
                <Card.Text>
                  <strong>Name:</strong> {studentViewData?.student_data?.full_name}
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong> {studentViewData?.student_data?.email}
                </Card.Text>
                <Card.Text>
                  <strong>Mobile Number:</strong> {studentViewData?.student_data?.phone}
                </Card.Text>
                <Card.Text>
                  <strong>Gender:</strong> {studentViewData?.student_data?.gender}
                </Card.Text>
                <Card.Text>
                  <strong>Pursuing Year:</strong> {getPursuingYearTitle(studentViewData?.student_data?.pursuing_year)}
                </Card.Text>
                <Card.Text>
                  <strong>Student ID:</strong> {studentViewData?.student_data?.student_id}
                </Card.Text>
                <Card.Text>
                  <strong>College Name:</strong> {getCollegeTitle(studentViewData?.student_data?.collage_id)}
                </Card.Text>
                <Card.Text className='text-capitalize'>
                  <strong>Approval Status:</strong>{' '}
                  {studentViewData?.student_data?.approval_status === 'approve'
                    ? 'approved'
                    : studentViewData?.student_data?.approval_status}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8} lg={8} style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
            <Tabs defaultActiveKey='loginHistory' id='student-tabs' className='mb-4'>
              {/* Tab 1 - Login History */}
              <Tab eventKey='loginHistory' title='Login History'>
                <Card className='shadow-sm mt-4' style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>IP Address</th>
                          <th>Device</th>
                          <th>Location</th>
                          <th>Login Time</th>
                          <th>Logout Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords?.length != 0 ? (
                          currentRecords?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.ip_address}</td>
                              <td>{item.device}</td>
                              <td>
                                {' '}
                                {item?.city || item?.region || item?.country
                                  ? `${item?.city ? `${item?.city},` : ''} ${item?.region ? `${item?.region},` : ''} ${
                                      item?.country ? `${item?.country}` : ''
                                    }`
                                  : '-'}
                              </td>
                              <td>{item.created_at ? moment(item.created_at).format('DD, MMM yyyy H:mm A') : '-'}</td>
                              <td>{item.updated_at ? moment(item.updated_at).format('DD, MMM yyyy H:mm A') : '-'}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className='text-center self-align-center'>
                              Data not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    {/* Pagination */}
                    {currentRecords?.length != 0 && (
                      <Pagination className='mt-2'>
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {[...Array(totalPages)]?.map((_, index) => (
                          <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => paginate(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    )}
                  </Card.Body>
                </Card>
              </Tab>

              {/* Tab 2 - Watch Count */}
              <Tab eventKey='watchCount' title='Watch Count'>
                <Card className='shadow-sm mt-4' style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                  <Card.Body>
                    <Accordion defaultActiveKey='0'>
                      {studentViewData?.watch_history?.length !== 0 ? (
                        studentViewData?.watch_history?.map((course, index) => (
                          <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{course?.name_of_course}</Accordion.Header>
                            <Accordion.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Lesson</th>
                                    <th>Watch Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {course.lessons.map((lesson, idx) => (
                                    <tr key={idx}>
                                      <td>{lesson.lesson_name}</td>
                                      <td>{lesson.watch}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      ) : (
                        <div className='d-flex justify-content-center'>No Data Found</div>
                      )}
                    </Accordion>
                  </Card.Body>
                </Card>
              </Tab>

              {/* Tab 3 - Badges */}
              <Tab eventKey='badges' title='Badges'>
                <Card className='shadow-sm mt-4' style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                  <Card.Body>
                    <Row>
                      {studentViewData?.badge_data?.length !== 0 ? (
                        studentViewData?.badge_data?.map(badge => (
                          <Col xs={6} md={3} key={badge.id}>
                            <div className=' space-x-2'>
                              <div className='d-flex flex-wrap justify-content-center p-5  bg-dark rounded shadow-lg'>
                                <div className='text-xl fw-semibold text-white'>
                                  {'Addition'}
                                  {/* {academicData?.map(i => {
                                if (i?.value == item?.pursuing_year) {
                                  return i?.label
                                }
                              })} */}
                                </div>
                                {/* <div className="text-lg font-medium text-gray-700">{"courseName"}</div> */}
                                <div className='mt-2 px-3 py-2 text-dark fw-semibold text-sm bg-white rounded-circle'>
                                  {badge?.badge}
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <div className='d-flex justify-content-center'>No Data Found</div>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Tab>

              {/* Tab 4 - Attachments */}
              <Tab eventKey='attachments' title='Attachments'>
                <Card className='shadow-sm mt-4' style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
                  <Card.Body>
                    <Accordion defaultActiveKey='0'>
                      {studentViewData?.student_data?.attachment?.length !== 0 ? (
                        studentViewData?.student_data?.attachment.map((yearData, index) => (
                          <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{getPursuingYearTitle(yearData?.pursuing_year)}</Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                {yearData.id_card && (
                                  <Col xs={12} md={6} lg={4} className='mb-3'>
                                    <Card>
                                      <Card.Body>
                                        <Card.Title className='text-center my-3'>
                                          <RenderImage src={yearData.id_card} fluid /> {`ID Card`}
                                        </Card.Title>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )}
                                {yearData.mark_sheet && (
                                  <Col xs={12} md={6} lg={4} className='mb-3'>
                                    <Card>
                                      <Card.Body>
                                        <Card.Title className='text-center my-3'>
                                          <RenderImage src={yearData.mark_sheet} fluid />
                                          {`Mark Sheet`}
                                        </Card.Title>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      ) : (
                        <div className='d-flex justify-content-center'>No Data Found</div>
                      )}
                    </Accordion>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default StudentViewPage
