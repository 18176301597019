import {
  faAnchor,
  faBoxOpen,
  faCab,
  faChartPie,
  faCheckToSlot,
  faCodeCommit,
  faFileAlt,
  faList,
  faMoneyBill,
  faMoneyBill1Wave,
  faNewspaper,
  faPeopleCarry,
  faQuestion,
  // faQuestionCircle,
  faRepeat,
  faTable,
  faWorm
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Badge, Button, Dropdown, Image, Nav, Navbar } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import SimpleBar from 'simplebar-react'

import { Routes } from '../routes'

export default () => {
  const location = useLocation()
  const { pathname } = location
  const [show, setShow] = useState(false)
  const showClass = show ? 'show' : ''

  const onCollapse = () => setShow(!show)

  const CollapsableNavItem = props => {
    const { eventKey, title, icon, children = null } = props

    const universityRoutes = [
      '/dashboard',
      '/free-course',
      '/university',
      '/coordinator',
      '/assign-course',
      '/assign-badges',
      '/university-report',
      '/create-university',
      '/update-university',
      '/university-quiz',
      '/create-free-course',
      '/all-free-lessons',
      '/all-free-chapter',
      '/create-free-chapter',
      '/view-free',
      '/promote-student'
    ]

    const universityStudentRoutes = ['/assign-course', '/assign-badges', '/promote-student']

    // const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : ''
    const defaultKey =
      pathname === '/all-courses' || pathname === '/create-course' || pathname === '/create-coupon'
        ? 'course/'
        : pathname === '/free-webinar' ||
          pathname === '/paid-webinar' ||
          pathname === '/notification' ||
          pathname === '/question-answers'
        ? 'webinar/'
        : pathname === '/all-students' || pathname === '/scholarship'
        ? 'students/'
        : pathname === '/live/webinar'
        ? 'webinar/'
        : pathname === '/user-report' || pathname === '/transaction-report'
        ? 'report/'
        : universityStudentRoutes?.some(path => pathname?.includes(path)) && eventKey == 'student/'
        ? 'student/'
        : universityRoutes?.includes(pathname) || universityRoutes?.some(path => pathname?.includes(path))
        ? 'university/'
        : ''

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className='d-flex justify-content-between align-items-center'>
            <span>
              {icon && (
                <span className='sidebar-icon'>
                  <FontAwesomeIcon icon={icon} />{' '}
                </span>
              )}
              <span className='sidebar-text'>{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className='multi-level'>
            <Nav className='flex-column'>{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }

  const NavItem = props => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = 'secondary',
      badgeColor = 'primary'
    } = props
    const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : ''
    const navItemClassName = link === pathname ? 'active' : ''

    const linkProps = external ? { href: link } : { as: Link, to: link }

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className='sidebar-icon'>
                <FontAwesomeIcon icon={icon} />
              </span>
            ) : null}
            {image ? <Image src={image} width={20} height={20} className='sidebar-icon svg-icon' /> : null}

            <span className='sidebar-text'>{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className='badge-md notification-count ms-2'>
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    )
  }

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant='dark' className='navbar-theme-primary px-4 d-md-none'>
        <Navbar.Brand className='me-lg-5' as={Link} to='/'>
          <h2 style={{ color: '#fff' }}>SHAH ADMIN</h2>
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls='main-navbar' onClick={onCollapse}>
          <span className='navbar-toggler-icon' />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames='sidebar-transition'>
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className='sidebar-inner px-4 pt-3'>
            <Nav className='flex-column pt-3 pt-md-0'>
              {/* <NavItem title='SHAH ADMIN PANEL' /> */}
              <p className='text-center'> SHAH ADMIN PANEL</p>

              <NavItem title='Dashboard' link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title='Courses Sold' icon={faMoneyBill} link={Routes.orders.path} />

              <CollapsableNavItem eventKey='course/' title='Course' icon={faTable}>
                <NavItem title='All Courses' link='/all-courses' />
                <NavItem title='Create Course' link='/create-course' />

                <NavItem title='Create Coupon' link='/create-coupon' />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey='webinar/' title='Webinar' icon={faTable}>
                <NavItem title='Free Webinar' link='/free-webinar' />
                <NavItem title='Paid Webinar' link='/paid-webinar' />
                <NavItem title='Organon with SHAH' link='/question-answers' />
                {/* <NavItem title='Notification' link='/notification' /> */}
              </CollapsableNavItem>

              <CollapsableNavItem eventKey='students/' title='Students' icon={faFileAlt}>
                <NavItem title='Students Details' link='/all-students' />
                <NavItem title='Scholarship' link='/scholarship' />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey='university/' title='University' icon={faFileAlt}>
                <NavItem title='Dashboard' link='/dashboard' />
                <NavItem title='Free Courses' link='/free-course' />
                <NavItem title='Universities' link='/university' />
                <NavItem title='Coordinators' link='/coordinator' />
                <CollapsableNavItem eventKey='student/' title='Students'>
                  <NavItem title='List Students' link='/assign-course' />
                  <NavItem title='Assign Badges' link='/assign-badges' />
                  <NavItem title='Promote Students' link='/promote-student' />
                </CollapsableNavItem>
                {/* <CollapsableNavItem eventKey='faculty/' title='Faculty'>
                  <NavItem title='Assign Course' link='/faculty' />
                </CollapsableNavItem> */}
                {/* <NavItem title='University Report' link='/university-report' /> */}
                {/* <NavItem title='University Quiz Score' link='/university-quiz' /> */}
              </CollapsableNavItem>

              <Dropdown.Divider className='my-3 border-indigo' />

              <CollapsableNavItem eventKey='webinar/' title='Live SHAH' icon={faBoxOpen}>
                <NavItem title='Webinar' link='/live/webinar' />
              </CollapsableNavItem>

              <NavItem title='FEEDBACK' icon={faPeopleCarry} link='/feed-back' />
              {/* <NavItem title='Organon with SHAH' icon={faQuestionCircle} link='/question-answers' /> */}
              <NavItem title='Quiz Score' icon={faQuestion} link='/quiz-score' />
              <NavItem title='Comments' icon={faCodeCommit} link='/comments' />
              <NavItem title='Typo Form' icon={faWorm} link='/all-typo-form' />
              <NavItem title='News Letter' icon={faNewspaper} link='/all-news-letter' />
              <NavItem title='Coach' icon={faCab} link='/coach-list' />
              <NavItem title='Chat' icon={faCheckToSlot} link='/chat' />
              <CollapsableNavItem eventKey='report/' title='Report' icon={faRepeat}>
                <NavItem title='User Report' link='/user-report' />
                <NavItem title='Transaction Report' link='/transaction-report' />
              </CollapsableNavItem>
              <NavItem title='Answers' icon={faAnchor} link='/all-answer' />
              <NavItem title='In App Purchase' icon={faAnchor} link='/in-app-purchase-user' />
              <NavItem title='In AppPurchase Code' icon={faMoneyBill1Wave} link='/in-app-purchase' />
              <NavItem title='SHAH LOGGER' icon={faList} link='/shah-logger' />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}
